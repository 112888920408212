import request from '@/utils/request'

export function login(username,password){
    const data = {
        username,
        password
    };
    return request({
        url: 'admin/login',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}

// 获取用户详细信息
export function getInfo() {
    return request({
        url: 'admin/getInfo',
        method: 'get'
    })
}
// 退出方法
export function logout() {
    return request({
        url: 'admin/logout',
        method: 'post'
    })
}