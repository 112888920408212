<template>
    <div style="border: 1px solid #ccc;" v-if="isAjaxDone">
        <!-- 工具栏 -->
        <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editorId="editorId"
                :defaultConfig="toolbarConfig"
        />
        <!-- 编辑器 -->
        <Editor

                style="height: 500px; overflow-y: hidden; text-align: left;"
                :defaultHtml="defaultHtml"
                :editorId="editorId"
                :defaultConfig="editorConfig"
                @onChange="onChange"
        />
    </div>
    <div v-else>
		<!-- 工具栏 -->
		<Toolbar
		        style="border-bottom: 1px solid #ccc"
		        :editorId="editorId"
		        :defaultConfig="toolbarConfig"
		/>
		<!-- 编辑器 -->
		<Editor
		
		        style="height: 500px; overflow-y: hidden; text-align: left;"
		        :defaultHtml="defaultHtml"
		        :editorId="editorId"
		        :defaultConfig="editorConfig"
		        @onChange="onChange"
		/>
	</div>
</template>

<script>

    // npm 安装

    import { Editor, Toolbar, getEditor, removeEditor } from '@wangeditor/editor-for-vue'
    import '@wangeditor/editor/dist/css/style.css'
    // import cloneDeep from 'lodash.clonedeep'
    export default {
        name: "WangEditor",
        components: { Editor, Toolbar },
        props:{
            msg: {
                type: String,
                default: ""
            },
            isClear: {
              type:Boolean,
              default: false
            }
        },
        data() {
            return {
                defaultHtml: '',
                editorId: 'wangEditor-1', // 定义一个编辑器 id ，要求：全局唯一且不变。重要！！！
                latestContent: [], // 用于存储编辑器最新的内容，onChange 时修改
                toolbarConfig: {},
                editorConfig: {
                    placeholder: '请输入内容...',
                },
                isAjaxDone: false,

            }
        },
        watch:{
            isClear(val){
                if (val){
                    const editor = getEditor(this.editorId);
                    editor.clear;
                }
            },
            msg(value){
                if (value !== undefined){
                    this.defaultHtml = value;
                    this.isAjaxDone = true;
                }
            }
        },
        methods: {
            onChange(editor){
                // console.log('onChange', editor.children) // onChange 时获取编辑器最新内容
                // console.log(editor.getHtml());
                // console.log(editor.getHtml());
                // this.latestContent = editor.children
            },
            beforeDestroy() {
                const editor = getEditor(this.editorId)
                if (editor == null) return
                editor.destroy() // 组件销毁时，及时销毁编辑器 ，重要！！！
                removeEditor(this.editorId)
            },
            getEditorHtml(){
                const editor = getEditor(this.editorId);
                return editor.getHtml();
            }
        }
    }
</script>
