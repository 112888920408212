import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import directive from './directive'
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import {handleTree,parseTime,resetForm} from './utils/ruoyi'
import './permission.js';


// 引入NProgress
import NProgress from 'nprogress'
// 引入nprogress样式文件
import'nprogress/nprogress.css'
Vue.use(NProgress);
// 分页组件
import Pagination from "@/components/Pagination";
import WangEditor from "@/components/WangEditor"

Vue.use(ElementUI);
// 引入echarts  
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
// 全局方法挂载
Vue.config.productionTip = false;
Vue.prototype.handleTree = handleTree;
Vue.prototype.parseTime = parseTime;
Vue.prototype.resetForm = resetForm;
// 全局组件挂载
Vue.component('Pagination', Pagination);
Vue.component('WangEditor', WangEditor);

Vue.use(directive);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
