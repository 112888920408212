<template>
  <div style="height: 100%">
    <div class="login">
      <div class="back">
        <el-form
          :model="ruleForm"
          :rules="rules"
          status-icon
          ref="ruleForm"
          label-width="80px"
          class="demo-ruleForm"
        >
          <img src="../assets/logo2.png" alt="" />
          <h2 style="margin-top: 50px; color: #1471ed; margin-left: 35px">
            欢迎登录
          </h2>
          <el-form-item prop="username">
            <el-input
              type="text"
              v-model="ruleForm.username"
              placeholder="请输入用户名"
              prefix-icon="el-icon-s-custom"
              autocomplete="off"
              style="width: 280px"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              show-password
              placeholder="请输入密码"
              v-model="ruleForm.password"
              prefix-icon="el-icon-lock"
              autocomplete="off"
              style="width: 280px"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="inputCode">
            <el-col :span="11">
              <el-input
                type="text"
                placeholder="请输入验证码"
                v-model="ruleForm.inputCode"
                prefix-icon="el-icon-lock"
                autocomplete="off"
                style="width: 148px"
              >
              </el-input>
            </el-col>

            <el-col :span="11">
              <span @click="createCode" id="spancode">
                <Sldentify :identifyCode="code"></Sldentify>
              </span>
            </el-col>
          </el-form-item>
          <!-- <el-row>
				  <el-col :span="12">
					  <div class="grid-content bg-purple">
						  <el-checkbox v-model="checked"></el-checkbox> 记住密码
					  </div>
				  </el-col>
				  <el-col :span="12">
					  <div class="grid-content bg-purple-light">
						   <a href="#">忘记密码？</a>
					  </div>
				  </el-col>
				</el-row> -->
          <el-form-item>
            <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
          </el-form-item>
          <el-button
            type="primary"
            :loading="loading"
            @click="submitForm('ruleForm')"
            style="width: 280px; margin-left: 35px"
          >
            <span v-if="!loading">登 录</span>
            <span v-else>登 录 中...</span>
          </el-button>
        </el-form>
      </div>
    </div>
    <div class="footer">
      Copyright © 学习培训考试系统 丨<a
        href="https://beian.miit.gov.cn"
        target="_blank"
        >豫ICP备 18018262 号</a
      >
    </div>
  </div>
</template>


<script>
import request from "../utils/request.js";
import Sldentify from "../components/SIdentify.vue";
import { setToken } from "../utils/auth.js";
// import qs from 'qs'
export default {
  components: {
    Sldentify,
  },
  data() {
    return {
      loading: false,
      ruleForm: {
        username: "",
        password: "",
        inputCode: "",
      },
      checked: false,
      rules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        inputCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      code: "",
    };
  },
  mounted() {
    this.createCode();
  },
  methods: {
    createCode() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      // 设置验证码长度，
      for (let i = 0; i < 4; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      this.code = text;
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            this.ruleForm.inputCode.toLowerCase() != this.code.toLowerCase()
          ) {
            this.$message({ message: "验证码错误", type: "error" });
            this.ruleForm.inputCode = "";
            this.createCode();
            return;
          }

          this.loading = true;
          let ruForm = {
            username: this.ruleForm.username,
            password: this.ruleForm.password,
          };
          this.$store
            .dispatch("Login", ruForm)
            .then(() => {
              this.$router.push({ path: "/Home/statistics" }).catch(() => {});
              sessionStorage.setItem("id", 999);
            })
            .catch(() => {
              this.loading = false;
              this.ruleForm.inputCode = "";
              this.createCode();
            });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>


<style scoped="scoped">
.login {
  width: 100%;
  height: 100%;
  /* background: rgb(231, 230, 230); */
  background-image: url(../assets/background.png);
  /* background-size: 100%  100%; */
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.back {
  min-width: 1100px;
  height: 600px;
  /* background: red; */
  background-image: url(../assets/backg.png);
  background-size: 100% 100%;
  position: relative;
}
.el-form {
  width: 400px;
  height: 500px;
  position: absolute;
  top: 80px;
  right: 120px;

  align-items: center; /* 垂直 */
  /* border:1px solid grey; */
}
.el-input {
  display: block !important;
}
.bg-purple {
  padding-left: 35px;
}
.bg-purple-light {
  padding-left: 40px;
}
.bg-purple-light a {
  text-decoration: none;
}
.footer {
  width: 100%;
  color: #ffffff;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 25px;
}
.footer a {
  color: #ffffff;
  text-decoration: none;
}
</style>