import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import { isRelogin } from '@/utils/request'

NProgress.configure({ showSpinner: false });

const whiteList = ['/login'];

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (getToken()) {
    // to.meta.title && store.dispatch('settings/setTitle', to.meta.title);
    /* has token*/
    if (to.path === '/') {
      next({ path: '/Home/adminis' });
      NProgress.done()
    } else {
      if (store.getters.roles.length === 0) {
        isRelogin.show = true;
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(() => {
          isRelogin.show = false;
          next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
        }).catch(err => {
            store.dispatch('LogOut').then(() => {
              Message.error(err);
              next({ path: '/' })
            })
          })
      } else {
        next()
      }
    }
  } else {
    // 没有token
    console.log(to.path);
    // next(`/?redirect=${to.fullPath}`) // 否则全部重定向到登录页
    if (to.path === '/')
    {
      next();
      NProgress.done()
    }else {
      next({path:'/'});
      NProgress.done()
    }

  }
});

router.afterEach(() => {
  NProgress.done()
});
