import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
    children: [
      {
        path: "statistics",
        name: "statistics",
        component: () => import("../views/statistics/index.vue"),
      },
      {
        path: "certificate",
        name: "certificate",
        component: () => import("../views/certificate/index.vue"),
      }, //证书管理
      {
        path: "menus",
        name: "menus",
        component: () => import("../views/system/menus.vue"),
        meta: { keepAlive: true },
      },
      {
        path: "Roleman",
        name: "Roleman",
        component: () => import("../views/Role/Roleman.vue"),
      },
      {
        path: "index",
        name: "index",
        component: () => import("../views/Role/index.vue"),
      },
      {
        path: "dept",
        name: "Dept",
        component: () => import("../views/dept/index.vue"),
      },
      {
        path: "adminis",
        name: "adminis",
        component: () => import("../views/administrators/index.vue"),
      }, //
      {
        path: "logininfor",
        name: "Logininfor",
        component: () => import("../views/monitor/logininfor/index.vue"),
        meta: { keepAlive: true },
      },
      {
        path: "operLog",
        name: "OperLog",
        component: () => import("../views/monitor/operLog/index.vue"),
        meta: { keepAlive: true },
      },
      {
        path: "system",
        name: "system",
        component: () =>
          import("../views/administrators/systemMmenu/index.vue"),
      },
      {
        path: "school",
        name: "school",
        component: () => import("../views/user/school/index.vue"),
      },
      {
        path: "website",
        name: "website",
        component: () => import("../views/management/index.vue"),
        meta: { keepAlive: true },
      },
      {
        path: "reward",
        name: "reward",
        component: () => import("../views/management/reward/index.vue"),
      },
      {
        path: "programa",
        name: "programa",
        component: () => import("../views/study/programa/index.vue"),
      },
      {
        path: "user",
        name: "user",
        component: () => import("../views/user/users/index.vue"),
        meta: { keepAlive: true },
      },
      {
        path: "fileManage",
        name: "fileManage",
        component: () => import("../views/user/fileManage/index.vue"),
      },
      {
        path: "scoreSummary",
        name: "scoreSummary",
        component: () => import("../views/user/scoreSummary/index.vue"),
      },
      {
        path: "substance",
        name: "substance",
        component: () => import("../views/study/substance/index.vue"),
        meta: { keepAlive: true },
      },
      {
        path: "newly",
        name: "newly",
        component: () => import("../views/study/substance/newly.vue"),
      },
      {
        path: "credits",
        name: "credits",
        component: () => import("../views/user/credits/index.vue"),
      },
      {
        path: "questionType",
        name: "questionType",
        component: () => import("../views/exam/questiontype/index.vue"),
      },
      {
        path: "questionBank",
        name: "questionBank",
        component: () => import("../views/exam/questionbank/index.vue"),
        meta: { keepAlive: true },
      },
      {
        path: "learning",
        name: "learning",
        component: () => import("../views/study/learning/index.vue"),
        meta: { keepAlive: true },
      },
      {
        path: "answer",
        name: "answer",
        component: () => import("../views/exam/answer/index.vue"),
        meta: { keepAlive: true },
      },
      {
        path: "unpdnew",
        name: "unpdnew",
        component: () => import("../views/exam/answer/unpdnew.vue"),
      },
      {
        path: "tentof",
        name: "tentof",
        component: () => import("../views/exam/answer/tentof.vue"),
      },
      {
        path: "addBank",
        name: "addBank",
        component: () => import("../views/exam/questionbank/addbank.vue"),
      },
      {
        path: "grades",
        name: "grades",
        component: () => import("../views/exam/grades/index.vue"),
        meta: { keepAlive: true },
      },
    ],
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting   substance
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
