<template>
  <div class="home">
    <el-container>
      <el-aside width="200px">
        <el-menu
          :default-active="actives"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
        >
          <el-menu-item
            index="999"
            @click="navigation(999, '/Home/statistics')"
            style="text-align: left"
            ><i class="el-icon-collection-tag"></i> 首页</el-menu-item
          >
          <el-submenu
            v-for="item in navrout"
            :index="item.id.toString()"
            :key="item.id"
          >
            <template slot="title">
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </template>

            <el-menu-item
              @click="navigation(its.id, its.url)"
              :index="its.id.toString()"
              v-for="its in item.children.meiyou"
              :key="its.id"
            >
              {{ its.title }}
            </el-menu-item>

            <el-submenu
              style="text-align: right"
              :key="ite.id"
              v-for="ite in item.children.you"
              :index="ite.id.toString()"
            >
              <template slot="title">{{ ite.title }}</template>
              <el-menu-item
                @click="navisan(it.id, it.url)"
                :key="it.id"
                v-for="it in ite.children"
                :index="it.id.toString()"
                >{{ it.title }}</el-menu-item
              >
            </el-submenu>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header>
          <div class="log"><img src="../assets/logo.png" alt="" /></div>
          <el-dropdown>
            <span class="el-dropdown-link">
              {{ name }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item  >修改密码</el-dropdown-item> -->
              <el-dropdown-item @click.native="Logout()"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-header>

        <el-main>
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
          <el-footer>
            Copyright © 学习培训考试系统 丨<a
              href="https://beian.miit.gov.cn"
              target="_blank"
              >豫ICP备 18018262 号</a
            >
          </el-footer>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
// import {  MessageBox } from 'element-ui'
import request from "../utils/request.js";

export default {
  data() {
    return {
      navrout: [],
      actives: "999",
      name: "",
    };
  },
  created() {
    this.Nav();

    // beforeunload事件在页面刷新时先触发
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("UserName", this.$store.getters.name);
    });

    this.info();
    // 在页面加载时读取sessionStorage
  },
  methods: {
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    info() {
      this.$store.dispatch("GetInfo").then((res) => {
        // console.log('res-------',res.data.user);
        sessionStorage.setItem("userId", res.data.user.userId);
        sessionStorage.setItem("schoolIdList", res.data.user.schoolIdList);
      });
      this.name = this.$store.getters.name;
      if (this.name == "") {
        this.name = sessionStorage.getItem("UserName");
      }
      if (this.actives !== 100) {
        let actions = sessionStorage.getItem("id");
        if (actions !== undefined && actions !== "") {
          this.actives = actions;
        }
      }
      // return request({
      //     url:'admin/getInfo',
      //     method:'get',
      // }).then(res=>{
      //  if(res.status === 200){
      //   //  let user = res.data
      //   // console.log(res.data.user)
      //     const info = {   /* 把接口里的data数据存到本地 方便下个页面获取  adminState*/
      //         data: res.data,
      //     };
      //     sessionStorage.setItem("user",JSON.stringify(info))
      //     console.log(sessionStorage.getItem("user"))
      //  }
      // })
    },
    //获取导航信息
    Nav() {
      return request({
        url: "admin/getRouters",
        method: "get",
      })
        .then((res) => {
          if (res.status === 200) {
            // console.log(res.data)
            this.navrout = res.data;
            for (let i = 0; i < this.navrout.length; i++) {
              // console.log(this.navrout[i])
              // debugger
              let MM = this.navrout[i].children;
              MM["you"] = [];
              MM["meiyou"] = [];
              for (let f = 0; f < MM.length; f++) {
                //  console.log(MM[f])
                if (MM[f].children.length == 0) {
                  MM.meiyou.push(MM[f]);
                } else {
                  MM.you.push(MM[f]);
                }
              }
            }
            // console.log(this.navrout)
          }
        })
        .catch(() => {
          //错误处理
        });
    },
    //点击导航跳转
    navigation(id, url) {
      //  console.log(url,id)
      sessionStorage.setItem("id", id);
      // console.log(sessionStorage.getItem("id"))
      let rul = url;
      this.$router.push(rul);
    },
    //点击导航跳转
    navisan(id, url) {
      // console.log(url,id,'00')
      sessionStorage.setItem("id", id);
      //  console.log(url)
      let rul = url;
      this.$router.push(rul);
    },

    //退出登录
    async Logout() {
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          sessionStorage.removeItem("user");
          sessionStorage.removeItem("id");
          this.$store.dispatch("LogOut").then(() => {
            this.$router.push({ path: "/" });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.log {
  height: 60px;
  position: absolute;
  top: 0;
  left: 10px;
  color: #409eff;
}
.log img {
  margin-top: 5px;
}

.el-header {
  /* background-color: #B3C0D1; */
  color: #333;
  /* text-align: center; */
  line-height: 60px;
  /* box-shadow:5px 5px 10px 5px red; */
}
.el-footer {
  /* background-color: #B3C0D1; */
  color: rgb(95, 95, 95);
  font-size: 14px;
  text-align: center;
  line-height: 60px;
  margin-top: 30px;
}
.el-footer a {
  color: rgb(95, 95, 95);
  text-decoration: none;
}
.el-aside {
  display: block;
  position: absolute;
  left: 0;
  top: 60px;
  bottom: 0;
  /* overflow: hidden; */
}
.el-aside::-webkit-scrollbar {
  display: none;
}
.el-menu-vertical-demo {
  overflow-y: hidden !important;
  overflow-x: hidden;
}
.el-main {
  height: 100%;
  background-color: #f7f7f7;
  position: absolute;
  min-width: 1200px;
  left: 200px;
  right: 0;
  top: 60px;
  bottom: 0;
  overflow-y: scroll;
}
.el-submenu__title {
  text-align: left;
}

.el-dropdown {
  /* cursor: pointer; */
  color: #409eff;
  cursor: pointer;
  /* z-index: 9999;  el-popper*/
  position: absolute !important;
  top: 0;
  right: 60px;
}
.el-dropdown-menu {
  top: 40px !important;
}
.el-submenu.is-active > .el-submenu__title {
  color: #027aff !important;
}
.el-submenu.is-active > .el-submenu__title i {
  color: #027aff !important;
}

.el-menu-item.is-active {
  color: #027aff !important;
  background: linear-gradient(to right, #e3f0ff, #feffff);
  border-left: 5px solid #3976e8;
}
.el-menu {
  border: none !important;
}
.el-menu .el-submenu .el-submenu__title {
  padding-left: 66px;
}
.el-menu--inline .el-submenu .el-submenu__title {
  text-align: center !important;
  padding-left: 13px !important;
}
</style>
